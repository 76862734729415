<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <h6 class="mb-0 text-uppercase flex-fill">Importer la signature</h6>
      <button
        v-if="!active"
        @click.prevent="addSignature()"
        type="button"
        class="btn btn-primary"
      >
        {{ libBouton }}
      </button>
    </div>
    <!-- <div class="mb-5" v-if="!active"> -->
      <div class="mb-2">
         <FilePondUploader
            ref="fileUploader"
            class="form-control col-12 md:col-12"
            @save="fileSavedSecurite($event)"
            :autoResize="true"
            rows="8"
          />
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from "vuex";
import FilePondUploader from "../uploader/FilePondUploaderOneFile.vue";
// import { uploadFile } from "../../../../config/api-config";
export default {
  components: {  FilePondUploader},
  props: ["value"],
  data() {
    return {
      activeItem: {},
      signature: this.value,
      signature2: { ...this.value },
      active: false,
      libBouton: "Enregistrer",
      editableItem:{}
      
    };
  },
  created() {
    console.log(this.signature2)
  },
  methods: {
    ...mapActions({
      // uploadSignature: "media/uploadSignature",
    }),
    updateSignature(lang) {
      this.activeItem = { ...lang };
      this.active = true;
      // this.$refs.editor.show()
    },
   
     fileSavedSecurite(files) {
      let imageUrl = this.editableItem.imageUrl;
      imageUrl = this.$refs.fileUploader.getFilesUrls();
      console.log(files);
      this.editableItem = {
        ...this.editableItem,
        imageUrl,
      };
      console.log(this.editableItem);
    },
  },
};
</script>
